import { Routes, Route } from "react-router-dom";

import "./App.css";

import AppBarView from "./AppBarView/AppBarView.js";

import LandingView from "./LandingView.js";
import ContactView from "./ContactView/ContactView.js";
import WidgetView from "./WidgetView/WidgetView.js";
import FooterView from "./FooterView/FooterView.js";
import SetupView from "./SetupView/SetupView.js";
import ShopifyFaqView from "./ShopifyFaqView/ShopifyFaqView.js";
import StyleGuideView from "./StyleGuideView/StyleGuideView.js";

// import NewAssetView from './NewAssetView.js';
// import GalleryView from "./GalleryView.js";
// import AssetView from "./AssetView";
// import ProfileView from './ProfileView.js';
// import EditView from './EditView/EditView.js';
// const initialFormState = { name: '', description: '' }

function App() {
    return (
        <div className="App">
            <AppBarView />
            <div
                style={{
                    paddingTop: "76px",
                }}
            >
                <Routes>
                    <Route path="/" element={<LandingView />} />
                    <Route path="/contact" element={<ContactView />} />
                    <Route path="/widget" element={<WidgetView />} />
                    <Route path="/setup" element={<SetupView />} />
                    <Route path="/shopify-faq" element={<ShopifyFaqView />} />
                    <Route path="/style" element={<StyleGuideView />} />
                    {/* <Route path="/profile" element={<ProfileView />} /> */}
                    {/* <Route path="/new" element={<NewAssetView />} /> */}
                    {/* <Route path="/gallery" element={<GalleryView />} /> */}
                    {/* <Route path="/assets/:id" element={<AssetView />} /> */}
                    {/* <Route path="/edit/:id" element={<EditView />} /> */}
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
            </div>
            <FooterView />
        </div>
    );
}

export default App;
