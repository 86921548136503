import "./SetupView.css";
import Container from "@mui/material/Container";
import { useState } from "react";

// Send pageview for /setup
import ReactGA from "react-ga4";
import SetupShopifyView from "./SetupShopifyView";
import SetupNonShopifyView from "./SetupNonShopifyView";
import SetupContactView from "./SetupContactView";
import SiteBuilderSelectView from "./SiteBuilderSelectView";
ReactGA.send({ hitType: "pageview", page: "/setup", title: "Setup Page" });

const SetupView = () => {
    const [builder, setBuilder] = useState("");

    const setSelectedBuilder = (builder) => {
        setBuilder(builder);
    };

    return (
        <div
            className="background-primary"
            style={{ minHeight: "calc(100vh - 120px)" }}
        >
            <Container maxWidth="lg">
                {!builder && (
                    <SiteBuilderSelectView
                        setSelectedBuilder={setSelectedBuilder}
                    />
                )}
                {builder && builder === "Shopify" && (
                    <SetupShopifyView
                        backEvent={() => setSelectedBuilder("")}
                    />
                )}
                {builder && builder === "Unsure" && (
                    <SetupContactView
                        backEvent={() => setSelectedBuilder("")}
                    />
                )}
                {builder && builder !== "Shopify" && builder !== "Unsure" && (
                    <SetupNonShopifyView
                        backEvent={() => setSelectedBuilder("")}
                    />
                )}
            </Container>
        </div>
    );
};

export default SetupView;
