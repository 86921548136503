import React from "react";
import Container from "@mui/material/Container";

import FAQSection from "./FAQSection";
import faqSections from "./faqs";

const ShopifyFaqView = () => {
    return (
        <div className="faq-page background-primary">
            <Container maxWidth="lg">
                <div className="section-title">
                    <div className="text-eyebrow foreground-highlight">
                        Shopify Blankwall FAQ
                    </div>
                    <div className="text-title">
                        We're here to answer all of your questions.
                    </div>
                    <p className="text-description">
                        Setting up a new app can be overwhelming. We're here to
                        make it seemless and help you every step of the way.
                    </p>
                </div>
            </Container>
            <Container maxWidth="md">
                {faqSections.map((section, index) => (
                    <FAQSection
                        key={index}
                        sectionHeader={section.sectionHeader}
                        faqs={section.faqs}
                    />
                ))}
            </Container>
        </div>
    );
};

export default ShopifyFaqView;
