import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const SiteBuilderSelectView = ({ setSelectedBuilder }) => {
    const siteBuilders = [
        {
            name: "Shopify",
            image: "shopify-black-logo.png",
        },
        {
            name: "Squarespace",
            image: "squarespace-logo-black.webp",
        },
        {
            name: "Wordpress",
            image: "wordpress-logo-standard.webp",
        },
        {
            name: "Wix",
            image: "wix_logo.png",
        },
        {
            name: "Custom/Other",
            image: "html5-logo-black.svg",
        },
        {
            name: "I'm Unsure",
        },
    ];
    return (
        <>
            <div className="section-title">
                <div className="text-eyebrow foreground-highlight">
                    Get Started
                </div>
                <div className="text-title">
                    Select Your Web Builder to Continue
                </div>
            </div>
            <Container style={{ paddingBottom: "3rem" }}>
                <Grid container spacing={2}>
                    {siteBuilders.map(({ name, image }) => (
                        <Grid item xs={12} md={6}>
                            <button
                                key={name}
                                className="sitebuilder-button"
                                onClick={() => setSelectedBuilder(name)}
                            >
                                {image ? (
                                    <img
                                        className="sitebuilder-button-image"
                                        src={image}
                                        alt={name}
                                    />
                                ) : (
                                    <div className="text-description">
                                        {name}
                                    </div>
                                )}
                            </button>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default SiteBuilderSelectView;
