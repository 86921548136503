import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { ContactViewButton } from "../ButtonTheme";
import Dialog from "@mui/material/Dialog"; // Import Dialog
import DialogTitle from "@mui/material/DialogTitle"; // Import DialogTitle
import DialogContent from "@mui/material/DialogContent"; // Import DialogContent
import DialogActions from "@mui/material/DialogActions"; // Import DialogActions
import Button from "@mui/material/Button"; // Import Button
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/ErrorOutline";

import "./ContactView.css";

export default function ContactView() {
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility
    const [modalType, setModalType] = useState("success");

    const firstname = useRef();
    const lastname = useRef();
    const user_org = useRef();
    const user_email = useRef();
    const user_message = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        if (isSubmitting) return; // If already submitting, return to prevent multiple submissions

        setIsSubmitting(true); // Set submitting state to true
        const form = e.target;

        emailjs
            .sendForm(
                "service_cehbqnt",
                "template_jf4065r",
                form,
                "v0ncQKEuUnc71ZMt7",
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setIsSubmitting(false);
                    setOpenModal(true);
                },
                (error) => {
                    console.log(error.text);
                    setIsSubmitting(false);
                    setModalType("failure");
                    setOpenModal(true);
                },
            );
    };

    function handleCloseModal() {
        setOpenModal(false); // Close the modal when the user clicks outside or on the close button
        navigate("/");
    }

    return (
        <div className="ContactView">
            <Grid container className="contact-container background-primary">
                <Grid className="contact-title" item xl sm>
                    <h1 className="contact-title-title">Ready to Onboard?</h1>
                    <a
                        className="contact-title-text"
                        href="mailto:hello@dreamscapelabs.com"
                    >
                        <br />
                        hello@dreamscapelabs.com
                    </a>
                </Grid>
                <Grid className="contact-form" item xl sm>
                    <form className="contact-form-emailJS" onSubmit={sendEmail}>
                        <h1 className="contact-form-title">
                            Let's Get Started.
                        </h1>
                        <div className="name-fields">
                            <TextField
                                className="contact-textfield"
                                inputRef={firstname}
                                type="text"
                                name="user_firstname"
                                placeholder="First Name"
                                id="contactform-firstName"
                                sx={{ py: "1ch", pr: "1ch", width: "25ch" }}
                            />
                            <TextField
                                className="contact-textfield"
                                inputRef={lastname}
                                type="text"
                                name="user_lastname"
                                placeholder="Last Name"
                                id="contactform-lastName"
                                sx={{ py: "1ch", width: "25ch" }}
                            />
                        </div>
                        <TextField
                            className="contact-textfield"
                            inputRef={user_email}
                            type="email"
                            name="user_email"
                            placeholder="Enter your email address"
                            id="contactform-client-email"
                            sx={{ py: "1ch", width: "100%", maxWidth: "51ch" }}
                        />
                        <TextField
                            className="contact-textfield"
                            inputRef={user_org}
                            type="text"
                            name="user_org"
                            placeholder="Organization name"
                            id="contactform-org"
                            sx={{ py: "1ch", width: "100%", maxWidth: "51ch" }}
                        />
                        <TextField
                            className="contact-textfield"
                            inputRef={user_message}
                            name="message"
                            id="contactform-message"
                            multiline
                            rows={4}
                            sx={{ py: "1ch", width: "100%", maxWidth: "51ch" }}
                            placeholder="Please provide a detailed description of your inquiry so that we can assist you as quickly as possible."
                        />
                        <ContactViewButton
                            disabled={isSubmitting}
                            className="contact-button"
                            sx={{ my: "1em" }}
                            type="submit"
                            variant="contained"
                            endIcon={<SendIcon />}
                        >
                            {isSubmitting ? "Sending..." : "Send"}
                        </ContactViewButton>
                    </form>
                </Grid>
            </Grid>

            {/* Modal */}
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                className="ContactViewDialog"
            >
                <DialogTitle className={`ContactViewDialogTitle ${modalType}`}>
                    {modalType === "success" ? (
                        <CheckCircleOutlineIcon
                            sx={{ fontSize: "3rem", color: "green" }}
                        />
                    ) : (
                        <ErrorIcon sx={{ fontSize: "3rem", color: "red" }} />
                    )}
                </DialogTitle>
                <DialogContent>
                    <h1 className="ContactViewDialogMessage">
                        {modalType === "success"
                            ? "Your email has been sent successfully!"
                            : "Failed to send, try emailing us directly."}
                    </h1>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseModal}
                        className="ContactViewDialogButton"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
