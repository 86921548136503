import {
    Grid,
    TextField,
    Select,
    MenuItem,
    Slider,
    ToggleButtonGroup,
    ToggleButton,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import React, { useState } from "react";
import "./WidgetConfigurator.css";

const defaultConfig = {
    ar_container_selector: "#blankwall-container",
    ar_image_selector: ".product-image",
    ar_dimensions_selector: ".product-description",
    backgroundColor: "#2F4F4F", // DarkSlateGrey in HEX
    borderColor: "#FFFFFF", // White in HEX
    borderWidth: "0px",
    borderRadius: "10px",
    buttonText: "View in Your Space",
    fontWeight: "400",
    color: "#F0FFF0", // HoneyDew in HEX
    padding: "10px 20px",
};

const WidgetConfigurator = () => {
    const [config, setConfig] = useState({
        ...defaultConfig,
    });

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        setConfig((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };
    // Handler for buttonText dropdown menu
    const handleButtonTextChange = (event) => {
        setConfig((prev) => ({
            ...prev,
            buttonText: event.target.value,
        }));
    };
    // Handler for fontWeight radio button change
    const handleFontWeightChange = (event) => {
        setConfig((prev) => ({
            ...prev,
            fontWeight: event.target.value,
        }));
    };
    // Handler for border radius slider
    const handleBorderRadiusChange = (event) => {
        const { value } = event.target;
        setConfig((prev) => ({
            ...prev,
            borderRadius: `${value}px`,
        }));
    };
    // Handler for border radius slider
    const handleBorderWidthChange = (event) => {
        const { value } = event.target;
        setConfig((prev) => ({
            ...prev,
            borderWidth: `${value}px`,
        }));
    };
    const getDefaultBorderWidthValue = () => {
        const borderWidthValue = config.borderWidth.split(" ")[0];
        return parseInt(borderWidthValue.replace("px", ""), 10);
    };

    const getDefaultBorderRadiusValue = () => {
        const borderRadiusValue = config.borderRadius.split(" ")[0];
        return parseInt(borderRadiusValue.replace("px", ""), 10);
    };

    // Handler for padding slider
    const handlePaddingChange = (event) => {
        const firstValue = event.target.value;
        const secondValue = firstValue * 2;
        setConfig((prev) => ({
            ...prev,
            padding: `${firstValue}px ${secondValue}px`,
        }));
    };
    // Function to extract and parse the numeric value from the first padding value
    const getFirstPaddingValue = () => {
        const paddingValue = config.padding.split(" ")[0];
        return parseInt(paddingValue.replace("px", ""), 10); // Remove 'px' and parse as integer
    };

    const convertColor = (hexColor) => {
        const hexToRgb = (hex) => {
            let r = parseInt(hex.slice(1, 3), 16),
                g = parseInt(hex.slice(3, 5), 16),
                b = parseInt(hex.slice(5, 7), 16);
            return `rgb(${r}, ${g}, ${b})`;
        };
        return hexToRgb(hexColor);
    };

    const generateCode = () => {
        // Convert colors to RGB for displaying in the code generation
        let configCopy = {
            // configCopy is used so the state is not modified directly.
            ...config,
            backgroundColor: convertColor(config.backgroundColor),
            borderColor: convertColor(config.borderColor),
            color: convertColor(config.color),
        };

        return `Blankwall.init(${JSON.stringify(configCopy, null, 4)});`;
    };

    const copyToClipboard = () => {
        const code = generateCode();
        navigator.clipboard.writeText(code);
        alert("Code Copied to Clipboard!");
    };

    return (
        <div className="widget-configurator-container">
            <div className="text-heading-small">AR Button Configurator</div>
            <p className="text-description">
                Use the live preview below to customize your AR button and
                auto-generate the required code.
            </p>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "2rem",
                }}
            >
                <button
                    className="preview-button"
                    style={{
                        wordBreak: "keep-all",
                        backgroundColor: config.backgroundColor,
                        color: config.color,
                        borderColor: config.borderColor,
                        borderRadius: config.borderRadius,
                        borderWidth: config.borderWidth,
                        padding: config.padding,
                        fontWeight: config.fontWeight,
                    }}
                >
                    {config.buttonText}
                </button>
            </div>
            {/* Configuration Section */}
            <div className="card">
                <Grid container spacing={8}>
                    <Grid item md={4}>
                        <h3>Container Selector:</h3>
                        <TextField
                            variant="outlined"
                            name="ar_container_selector"
                            value={config.ar_container_selector}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <h3>Image Selector:</h3>
                        <TextField
                            variant="outlined"
                            name="ar_image_selector"
                            value={config.ar_image_selector}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <h3>Dimensions Selector:</h3>
                        <TextField
                            variant="outlined"
                            name="ar_dimensions_selector"
                            value={config.ar_dimensions_selector}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={8} style={{ paddingTop: "2rem" }}>
                    <Grid item md={4} sm={6} xs={12}>
                        <div style={{ display: "flex", columnGap: "1rem" }}>
                            <h3>Border Width:</h3>
                            <h3>{getDefaultBorderWidthValue()}px</h3>
                        </div>
                        <Slider
                            className="slider"
                            type="range"
                            name="padding"
                            step={1}
                            min={0}
                            max={8}
                            value={getDefaultBorderWidthValue()}
                            onChange={handleBorderWidthChange}
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div style={{ display: "flex", columnGap: "1rem" }}>
                            <h3>Border Radius:</h3>
                            <h3>{getDefaultBorderRadiusValue()}px</h3>
                        </div>
                        <Slider
                            className="slider"
                            type="range"
                            name="borderRadius"
                            step={2}
                            min={0}
                            max={16}
                            value={getDefaultBorderRadiusValue()}
                            onChange={handleBorderRadiusChange}
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <div style={{ display: "flex", columnGap: "1rem" }}>
                            <h3>Padding:</h3>
                            <h3>{getFirstPaddingValue()}px</h3>
                        </div>
                        <Slider
                            className="slider"
                            type="range"
                            name="padding"
                            step={2}
                            min={6}
                            max={20}
                            value={getFirstPaddingValue()}
                            onChange={handlePaddingChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={8} style={{ paddingTop: "2rem" }}>
                    <Grid item md={4} sm={12}>
                        <div
                            style={{
                                display: "flex",
                                columnGap: "0.5rem",
                                alignItems: "center",
                            }}
                        >
                            <h3>Background Color:</h3>
                            <input
                                className="color-picker"
                                type="color"
                                name="backgroundColor"
                                value={config.backgroundColor}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={12}>
                        <div
                            style={{
                                display: "flex",
                                columnGap: "0.5rem",
                                alignItems: "center",
                            }}
                        >
                            <h3> Border Color:</h3>
                            <input
                                className="color-picker"
                                type="color"
                                name="borderColor"
                                value={config.borderColor}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Grid>

                    <Grid item md={4} sm={12}>
                        <div
                            style={{
                                display: "flex",
                                columnGap: "0.5rem",
                                alignItems: "center",
                            }}
                        >
                            <h3>Font Color:</h3>
                            <input
                                className="color-picker"
                                type="color"
                                name="color"
                                value={config.color}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={8} style={{ paddingTop: "2rem" }}>
                    <Grid item md={4} xl={4}>
                        <h3>Button Text:</h3>
                        <Select
                            name="buttonText"
                            value={config.buttonText}
                            onChange={handleButtonTextChange}
                        >
                            <MenuItem value="View in Your Space">
                                View in Your Space
                            </MenuItem>
                            <MenuItem value="View in AR">View in AR</MenuItem>
                            <MenuItem value="View in Room">
                                View in Room
                            </MenuItem>
                            <MenuItem value="View on Your Wall">
                                View on Your Wall
                            </MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={4} xl={4}>
                        <div>
                            <h3>Font Weight:</h3>
                            <ToggleButtonGroup
                                value={config.fontWeight}
                                exclusive
                                onChange={handleFontWeightChange}
                            >
                                <ToggleButton value="200">Ligher</ToggleButton>
                                <ToggleButton value="400">Normal</ToggleButton>
                                <ToggleButton value="600">Bold</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" paddingY="2rem">
                    <Grid item md={8}>
                        <pre className="generated-code">{generateCode()}</pre>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <button
                            className="button"
                            style={{ width: "400px" }}
                            onClick={copyToClipboard}
                        >
                            <ContentCopyIcon
                                sx={{
                                    fontSize: "large",
                                    paddingRight: "0.4rem",
                                }}
                            />
                            Copy Code
                        </button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default WidgetConfigurator;
