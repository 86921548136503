import { useState, useEffect } from 'react';

const useViewport = () => {
  const [isMdOrLarger, setIsMdOrLarger] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isLargeEnough = window.innerWidth >= 900; 
      setIsMdOrLarger(isLargeEnough);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial render

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMdOrLarger;
};

export default useViewport;