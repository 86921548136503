import React, { Component } from "react";

import HeroView from "./HeroView/HeroView.js";
import Testimonials from "./TestimonialsView/TestimonialsView.js";
import FeatureView from "./FeatureView/FeatureView.js";
import EmailUsView from "./EmailUsView/EmailUsView.js";
import IntegrationView from "./IntegrationView/IntegrationView.js";
// import PartnerView from './PartnerView.js';
// import PricingView from './PricingView.js';

class LandingView extends Component {
    render() {
        return (
            <div>
                <HeroView />
                <Testimonials />
                <IntegrationView />
                <FeatureView />
                <EmailUsView />
            </div>
        );
    }
}

export default LandingView;
