
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const HeroViewButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    height: '46px',
    minWidth: '170px',
    maxWidth: '200px',
    padding: '6px 12px',
    border: '1px solid',
    borderRadius: '6px',
    lineHeight: 1.5,
    color: '#000',
    backgroundColor: '#transparent',
    borderColor: '#000000',
    fontFamily: [
        'nunito sans',
        'sans-serif',
    ].join(','),
    '&:hover': {
        backgroundColor: '#000',
        borderColor: '#000000',
        boxShadow: 'none',
        color: '#fff'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

const IntegrationViewButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    height: '46px',
    minWidth: '170px',
    maxWidth: '200px',
    padding: '6px 12px',
    border: '1px solid',
    borderRadius: '6px',
    lineHeight: 1.5,
    color: '#ffffff',
    backgroundColor: '#4b655a',
    borderColor: '#ffffff',
    fontFamily: [
        'nunito sans',
        'sans-serif',
    ].join(','),
    '&:hover': {
        backgroundColor: '#000',
        borderColor: '#000000',
        boxShadow: 'none',
        color: '#fff'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

const AppBarViewButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    textDecoration:'none',
    color: 'rgb(20, 20, 20)',
    fontSize: '1rem',
    fontWeight:  '600',
    padding: '6px 12px',
    border: '1px solid',
    borderRadius: '6px',
    lineHeight: 1.5,
    borderColor: 'transparent',
    height: '38px',
    fontFamily: [
        'nunito sans',
        'sans-serif',
    ].join(','),
    '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#000',
        boxShadow: 'none',
        color: '#000'

    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

const AppBarViewContactButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight:  '600',
    padding: '6px 18px',
    border: '1px solid',
    borderRadius: '6px',
    lineHeight: 1.5,
    color: "rgb(222, 222, 222)",
    backgroundColor: 'rgb(20,20,20)',
    borderColor: 'rgb(20,20,20)',
    height: '38px',
    fontFamily: [
        'nunito sans',
        'sans-serif',
    ].join(','),
    '&:hover': {
        backgroundColor: 'transparent',
        borderColor: '#000',
        boxShadow: 'none',
        color: '#000'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

const TestimonialsViewButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    height: '46px',
    minWidth: '170px',
    maxWidth: '200px',
    padding: '6px 12px',
    border: '1px solid',
    borderRadius: '6px',
    lineHeight: 1.5,
    color: '#fff',
    backgroundColor: '#000',
    borderColor: '#000000',
    fontFamily: [
        'nunito sans',
        'sans-serif',
    ].join(','),
    '&:hover': {
        backgroundColor: '#fff',
        borderColor: '#000000',
        boxShadow: 'none',
        color: '#000'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

const ContactViewButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    height: '46px',
    minWidth: '150px',
    maxWidth: '150px',
    padding: '6px 12px',
    border: '1px solid',
    borderRadius: '6px',
    lineHeight: 1.5,
    color: '#000',
    backgroundColor: 'transparent',
    borderColor: '#000000',
    fontFamily: [
        'nunito sans',
        'sans-serif',
    ].join(','),
    '&:hover': {
        backgroundColor: '#000',
        borderColor: '#000000',
        boxShadow: 'none',
        color: '#fff'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}));

export {IntegrationViewButton, AppBarViewButton, AppBarViewContactButton, TestimonialsViewButton, HeroViewButton, ContactViewButton};
