// src/faqs.js
const faqSections = [
    {
        sectionHeader: "AR Button",
        faqs: [
            {
                question:
                    "Can I customize how the AR button looks in my store?",
                answer: "Yes! Head over to your theme editor and select the Blankwall block on your product page. You can find a comprehensive list of button customizations on the side panel.",
                image: "/buttoncustomization.png",
            },
            {
                question: "Can I hide the button on certain products?",
                answer: "Yes! Add the <code>blankwall.exclude</code> tag to your product, and the button will not be displayed.",
            },
            {
                question:
                    "Help! I added the button but it's not working when I click it!",
                answer: "Don't worry, we're here to support you every step of the way. Send us an email to help@dreamscapelabs.com with a link to your product page. One of our expert developers will be right with you!",
            },
        ],
    },
    {
        sectionHeader: "AR Experience",
        faqs: [
            {
                question: "What devices support the AR experience?",
                answer: "Blankwall's AR experience is supported on iPhones, iPads, Apple Vision Pro, as well as most Android devices. Blankwall leverages Android and iOS's built in AR viewer, so users don't need to download any addtional apps to experience the AR preview.",
            },
            {
                question:
                    "Help! I can't get the artwork to show up properly on the wall!",
                answer: "AR preview uses your device's camera to identify walls in your space. To ensure a good experience, make sure your room is well lit and take a few steps back to let the camera see more of the scene. Including decor and adjacent walls/floor will improve the tracking performance.",
                image: "/arscene.jpg",
            },
        ],
    },
    {
        sectionHeader: "Artwork Dimensions",
        faqs: [
            {
                question: "How does Blankwall know what size my product is?",
                answer: "Blankwall intelligently analyzes your product listing for dimensions and units. These exact measurements are used to build our 3D model, guaranteeing that your customer's preview is true to scale.<br />We support dimensions provided in <code>width × height</code>, <code>height × width</code>, and even DIN paper formats such as <code>A0</code>-<code>A9</code>.",
            },
            {
                question: "Does Blankwall work with size variants?",
                answer: "Yes! Blankwall automatically detects when a user has selected a specific size variant on your product, and generates the experience in the correct size.",
            },
        ],
    },
    {
        sectionHeader: "Artwork Image",
        faqs: [
            {
                question:
                    "Can I choose which image is used for the AR preview?",
                answer: "Yes! Blankwall defaults to using the first image on your product listing, and this index can be changed in the App Settings.",
            },
            {
                question:
                    "Help! My product image includes a staged background and it's showing up in the AR preview!",
                answer: "Blankwall works best with artwork images that do not contain frames, whitespace, or backgrounds.<br/><br/>If you are unable to provide a clean image, you can enable Blankwall's built-in auto-crop feature in the App Settings. Auto-crop will attempt to intelligently crop out backgrounds from the image provided. Please note that this feature will result in increased load times.",
                image: "/autocrop.jpg",
            },
            {
                question:
                    "Can I use a specific image for the AR preview without including it in the product listing?",
                answer: "Yes! Simply create a product metafield with the key name <code>blankwall.image</code>, and upload the image you would like to use. Blankwall will prioritize using the metafield image first, and fall back to a product image if it is not found.<br /><br />To create a product metafield, navigate to your Shopify Store Settings → Custom data → Products.",
                list: [
                    "Namespace and key: <code>blankwall.image</code>",
                    "Type: File → One File",
                    "Validations: Accept specific file types → Images",
                    "Storefront Access: Enabled",
                ],
            },
            {
                question:
                    "Does Blankwall support multi-panel artworks such as diptychs and triptychs?",
                answer: "Yes! Simply create a product metafield with the key name <code>blankwall.multipanel</code>, and upload the images for each panel of the set. Images will be displayed in left to right order in the AR preview.<br /><br />To create a product metafield, navigate to your Shopify Store Settings → Custom data → Products.",
                list: [
                    "Namespace and key: <code>blankwall.multipanel</code>",
                    "Type: File → List of Files",
                    "Validations: Accept specific file types → Images",
                    "Storefront Access: Enabled",
                ],
                image: "/multipanel.jpg",
            },
        ],
    },
    {
        sectionHeader: "Frame Visualizer",
        faqs: [
            {
                question: "Can I customize or disable the frame selector?",
                answer: "Yes! You can configure the built-in frame visualizer in the App Settings.",
            },
            {
                question:
                    "Can I integrate the frame visualizer with the existing frame variants I offer?",
                answer: "Yes! You can configure custom frame presets in the App Settings, and then check the <code>Enable Frame Variant Auto-Match</code> checkbox. When a user selects a frame variant on your product page, the matching frame preset will automatically be included in the visualization.",
            },
        ],
    },
    {
        sectionHeader: "Other",
        faqs: [
            {
                question: "What languages does Blankwall support?",
                answer: "Blankwall's button and user interface natively supports a number of locales: <code>en</code>, <code>es</code>, <code>de</code>, <code>fr</code>, <code>it</code>, <code>nl</code>, <code>ar</code>, <code>da</code>, <code>it</code>, <code>no</code>, <code>ro</code>, <code>sv</code>.<br />If you operate in a region we don't currently support, don't hesitate to to let us know at help@dreamscapelabs.com!",
            },
            {
                question:
                    "Does Blankwall really support an unlimited number of products?",
                answer: "Yes! We align our success with yours, that's why Blankwall usage is only counted when a customer previews a product in AR. We don't charge money based on the number of products you have, and our fee structure only kicks in when we've demonstrated our value to your store through increased customer engagement.",
            },
            {
                question:
                    "I run a print-on-demand business where users upload their own images. Can Blankwall support this?",
                answer: "Yes! Please reach out to help@dreamscapelabs.com for integration details.",
            },
            {
                question:
                    "I sell wall decor that is not rectangular or has transparent sections. Can Blankwall support this?",
                answer: "Yes! Blankwall supports transparency if the product image used is in <code>.png</code> format. Framing will automatically be disabled in this mode.",
            },
        ],
    },
];

export default faqSections;
