import React from "react";
import Container from "@mui/material/Container";
import Slider from "react-slick";

import "./TestimonialsView.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonials() {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 840,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 390,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
        ],
    };

    const testimonials = [
        {
            quote: "It's so easy to set up and works flawlessly. The developers were extremely helpful in helping to deploy a customised version to my store...",
            location: "United Kingdom",
            store: "Many Lenses",
        },
        {
            quote: "Certainly one of the best AR application we've used to date...Their openness to suggestions and commitment to implementing new features is truly excellent!",
            location: "France",
            store: "What if Paris",
        },
        {
            quote: "super-stable and shows our catalogue of available artworks perfectly placed in space. no additional editing necessary it's fully automated...",
            location: "Germany",
            store: "Nikolai Makarov",
        },
    ];

    return (
        <div className="background-secondary">
            <Container maxWidth="xl">
                <div
                    className="testimonials-container"
                    style={{ padding: "4rem 1rem" }}
                >
                    <div
                        className="text-heading"
                        style={{ paddingBottom: "3rem" }}
                    >
                        Over{" "}
                        <span className="foreground-highlight">20,000+</span>{" "}
                        Experiences Enabled
                        <br />
                        Across{" "}
                        <span className="foreground-highlight">150+</span>{" "}
                        Galleries and Storefronts
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            overflow: "hidden",
                        }}
                    >
                        <div style={{ display: "flex", gap: "4rem" }}>
                            <img
                                className="logo-image"
                                src="logo-artify.png"
                                alt="logo"
                            />
                            <img
                                className="logo-image"
                                src="logo-gimle.png"
                                alt="logo"
                            />
                            <img
                                className="logo-image"
                                src="logo-pj.png"
                                alt="logo"
                            />
                            <img
                                className="logo-image"
                                src="logo-hof.png"
                                alt="logo"
                            />
                            <img
                                className="logo-image"
                                src="logo-paris.png"
                                alt="logo"
                            />
                            <img
                                className="logo-image"
                                src="logo-ef.png"
                                alt="logo"
                            />
                            <img
                                className="logo-image"
                                src="logo-tc.png"
                                alt="logo"
                            />
                        </div>
                    </div>
                    <Slider {...settings}>
                        {testimonials.map((data, index) => (
                            <div
                                key={index}
                                className="testimonials-content"
                                align="left"
                            >
                                <div
                                    style={{
                                        fontFamily: "Palatino",
                                        fontSize: "4rem",
                                        lineHeight: "4rem",
                                        marginBottom: "-1rem",
                                    }}
                                >
                                    “
                                </div>
                                <div
                                    className="text-description"
                                    style={{
                                        fontStyle: "italic",
                                        width: "85%",
                                    }}
                                >
                                    {data.quote}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Container>
        </div>
    );
}

export default Testimonials;
