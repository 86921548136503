import Grid from "@mui/material/Grid";
import "../FooterView/FooterView.css";

export default function FooterView() {
    return (
        <footer className="FooterView">
            <Grid container className="footer-container">
                {/* Copyright */}
                <Grid
                    item
                    xs={6}
                    sm={6}
                    lg={6}
                    xl={6}
                    className="footer-right-column"
                >
                    <div className="footer-dslabs-copyright">
                        &copy; {new Date().getFullYear()} DS Labs Technology
                        Corporation
                    </div>
                </Grid>
            </Grid>
        </footer>
    );
}
