import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

const SetupNonShopifyView = ({ backEvent }) => {
    const navigate = useNavigate();

    const navToWidget = () => {
        navigate("/widget");
    };

    const navToContact = () => {
        navigate("/contact");
    };
    return (
        <>
            <button className="back-button" onClick={() => backEvent()}>
                ←
            </button>{" "}
            <div className="section-title">
                <div className="text-heading">
                    Are you familiar with HTML and are comfortable editing your
                    webpage?
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <button
                        className="sitebuilder-button"
                        onClick={navToWidget}
                    >
                        <div className="text-heading-small">Yes</div>
                        <div className="text-description">
                            I'm confident and can edit my site's code.
                        </div>
                    </button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <button
                        className="sitebuilder-button"
                        onClick={navToContact}
                    >
                        <div className="text-heading-small">No</div>
                        <div className="text-description">
                            I need some assistance with integration.
                        </div>
                    </button>
                </Grid>
            </Grid>
        </>
    );
};
export default SetupNonShopifyView;
