import ContactView from "../ContactView/ContactView";

const SetupContactView = ({ backEvent }) => {
    return (
        <>
            <button className="back-button" onClick={() => backEvent()}>
                ←
            </button>{" "}
            <ContactView />
        </>
    );
};
export default SetupContactView;
