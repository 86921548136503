import React from "react";
import FAQItem from "./FAQItem";

const FAQSection = ({ sectionHeader, faqs }) => {
    return (
        <div className="faq-section">
            <div className="text-heading">{sectionHeader}</div>
            {faqs.map((faq, index) => (
                <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    image={faq.image}
                    list={faq.list}
                />
            ))}
        </div>
    );
};

export default FAQSection;
