import Grid from "@mui/material/Grid";

const SetupShopifyView = ({ backEvent }) => {
    return (
        <>
            <button className="back-button" onClick={() => backEvent()}>
                ←
            </button>
            <div className="section-title">
                <div className="text-heading">
                    Install our Blankwall app from the Shopify App store
                </div>
            </div>
            <Grid container sx={{ paddingBottom: "3rem" }}>
                <Grid item xs={12} md={7}>
                    <img
                        style={{ width: "100%" }}
                        src="step-1.png"
                        alt="platform-shopify"
                    />
                </Grid>
                <Grid item xs={12} md={5} sx={{ alignSelf: "center" }}>
                    <div style={{ padding: "2rem" }}>
                        <ul className="text-description">
                            <li>Find us on Shopify App Store</li>
                            <li>Add Blankwall button to your theme</li>
                            <li>Done!</li>
                        </ul>
                        <a
                            href="https://apps.shopify.com/blankwall"
                            style={{ width: "160px", margin: "0" }}
                        >
                            <img
                                className="setup-shopify-button"
                                src="Shopify-App-Store-Badge-Final-Black.svg"
                                alt="find-on-shopify"
                            />
                        </a>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};
export default SetupShopifyView;
