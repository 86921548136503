/* global Blankwall */
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import "./ProductListing.css";

const ProductListing = () => {
    useEffect(() => {
        // Load the remote script
        const script = document.createElement("script");
        script.src = "https://dfv2bs0cn166o.cloudfront.net/blankwall.umd.cjs";
        script.async = true;
        document.body.appendChild(script);

        // Call a function from the remote script once it's loaded
        script.onload = () => {
            Blankwall.init(
                {
                    ar_container_selector: "#blankwall-container",
                    ar_image_selector: ".ar_image_class",
                    ar_dimensions_selector: ".ar_dimensions_class",
                },
                {
                    buttonText: "View in AR",
                    backgroundColor: "darkgreen",
                    fontWeight: "500",
                    borderColor: "black",
                    borderRadius: "0px",
                    border: "none",
                    color: "white",
                    padding: "10px 20px",
                },
            );
            console.log("Blankwall Widget loaded");
        };

        return () => {
            // Clean up by removing the script from the DOM
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="mac-window">
            <div className="mac-header">
                <div className="ui-menu-circle ui-menu-red"></div>
                <div className="ui-menu-circle ui-menu-yellow"></div>
                <div className="ui-menu-circle ui-menu-green"></div>
            </div>
            <div className="mac-content">
                <Grid
                    container
                    className="background-primary"
                    sx={{ padding: { xs: "0rem", md: "3rem" } }}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                        sx={{
                            display: "flex",
                        }}
                    >
                        <img
                            style={{
                                width: "auto",
                                maxWidth: "100%",
                                maxHeight: "600px",
                                margin: "0 auto",
                                aspectRatio: "1426/1783",
                            }}
                            src="coupdevent.jpg"
                            alt="automagically"
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <div style={{ padding: "2rem" }}>
                            <div
                                style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                    opacity: "0.4",
                                }}
                            >
                                YOUR STORE
                            </div>
                            <div style={{ fontSize: "2rem" }}>Coup De Vent</div>
                            <h2 style={{ fontSize: "1rem", fontWeight: "300" }}>
                                Claude Monet
                            </h2>
                            <div>★★★★★</div>
                            <hr />
                            <div>
                                <div
                                    style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    1881, Oil on Canvas
                                </div>
                                <div className="ar_dimensions_class">
                                    75 × 93.5 cm
                                </div>

                                <div
                                    style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    AR experiences are automagically generated
                                    from your product listings.
                                </div>
                            </div>

                            <h1
                                className="foreground-highlight"
                                style={{
                                    fontSize: "1.2rem",
                                    paddingTop: "1rem",
                                }}
                            >
                                {"\u2193"} See it in action!
                                <br />
                            </h1>

                            <div id="blankwall-container">
                                <img
                                    className="ar_image_class"
                                    src="https://blankwalldemo.myshopify.com/cdn/shop/files/coupdevent.jpg?v=1709465711"
                                    alt="Coup De Vent"
                                    style={{ display: "none" }}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default ProductListing;
