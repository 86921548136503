import React from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import "./WidgetView.css";

function CodeSnippet({ code }) {
    function copyCode() {
        navigator.clipboard
            .writeText(code)
            .then(() => {
                alert("Code copied to clipboard!");
            })
            .catch((error) => {
                console.error("Error copying code: ", error);
            });
    }

    return (
        <div className="widget-code-container">
            <div className="widget-code-header">
                <IconButton
                    sx={{ fontSize: "14px" }}
                    className="widget-copy-button"
                    onClick={() => copyCode()}
                    size="small"
                >
                    <ContentCopyIcon
                        sx={{ fontSize: "medium", paddingRight: "0.4rem" }}
                    />
                    Copy Code
                </IconButton>
            </div>
            <div className="widget-code-content">
                <pre>
                    <code>{code}</code>
                </pre>
            </div>
        </div>
    );
}

export default CodeSnippet;
