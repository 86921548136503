import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useInView } from "react-intersection-observer";
import "./FeatureView.css";
import useViewport from "../useViewport";

function FeatureView() {
    const isMdOrLarger = useViewport();

    // Elements are outside the viewport causing overflow,
    // Need to figure out how to place them inside the viewport initially while keeping the slide in animations
    const [feature1, inView1] = useInView({
        triggerOnce: true, // Only trigger once
        threshold: 0.2,
        // temporary fix to initial overflow problem
    });

    const [feature2, inView2] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const [feature3n4, inView3] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    const [titleRef, inView4] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    return (
        <div className="background-secondary">
            <Container maxWidth="xl">
                <div
                    className={`section-title ${inView4 && isMdOrLarger ? "fade-in" : ""}`}
                    ref={titleRef}
                >
                    <div className="text-eyebrow foreground-highlight">
                        Benefits
                    </div>
                    <div className="text-title">The Best AR Experience</div>
                    <p className="text-description">
                        For you and your customers, across all platforms.
                    </p>
                </div>
                <Grid
                    container
                    className="background-primary card"
                    style={{ marginBottom: "4rem" }}
                >
                    {/* Feature 1 */}
                    <Grid
                        item
                        sm={12}
                        md={5}
                        ref={feature1}
                        className={
                            isMdOrLarger
                                ? inView1
                                    ? "show-feature"
                                    : "slide-left"
                                : "show-feature"
                        }
                        style={{ display: "flex" }}
                    >
                        <Box
                            alignContent={"center"}
                            style={{ padding: "2rem" }}
                        >
                            <div className="text-heading">
                                Effortless Photo-realistic Visualizations
                            </div>
                            <p className="text-description">
                                No manual per-product setup, and no additional
                                work to support new products.
                                <br />
                                Exhibit your products in customers' spaces with
                                color accurate and true to scale previews.
                            </p>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        sm={12}
                        md={7}
                        ref={feature1}
                        className={
                            isMdOrLarger
                                ? inView1
                                    ? "show-feature"
                                    : "slide-right"
                                : "show-feature"
                        }
                    >
                        <div style={{ padding: "0rem 2rem" }}>
                            <img
                                src="Feature-1.webp"
                                alt="color-accurate"
                                className="feature-image"
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid
                    container
                    className="background-primary card"
                    style={{ marginBottom: "4rem" }}
                >
                    <Grid
                        item
                        sm={12}
                        md={7}
                        ref={feature2}
                        className={
                            isMdOrLarger
                                ? inView2
                                    ? "show-feature"
                                    : "slide-left"
                                : "show-feature"
                        }
                    >
                        <div style={{ padding: "2rem" }}>
                            <img
                                src="Feature-2.webp"
                                alt="custom-frame"
                                className="feature-image"
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={5}
                        ref={feature2}
                        className={
                            isMdOrLarger
                                ? inView2
                                    ? "show-feature"
                                    : "slide-right"
                                : "show-feature"
                        }
                        style={{ display: "flex" }}
                    >
                        <Box
                            alignContent={"center"}
                            style={{ padding: "0rem 2rem" }}
                        >
                            <div className="text-heading">
                                Customizable Frames
                            </div>
                            <p className="text-description">
                                Showcase your catalog with our frame presets for
                                a stunning result.
                                <br />
                                <b>Have your own design?</b> Use our Custom
                                Frame tool to create your own presets.
                            </p>
                        </Box>
                    </Grid>
                </Grid>

                {/* Feature Column */}
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        ref={feature3n4}
                        className={
                            isMdOrLarger
                                ? inView3
                                    ? "show-feature"
                                    : "slide-left"
                                : "show-feature"
                        }
                        style={{ padding: "3rem" }}
                    >
                        <div className="feature-info-column-left">
                            <div className="text-heading">
                                Never Leave Your Site
                            </div>
                            <p className="feature-info-paragraph text-description">
                                No redirecting with third-party links. No
                                separate downloads. Your customers and assets
                                stay with you.
                            </p>
                            <div className="feature-image-normal">
                                <img
                                    src="Feature-3.webp"
                                    alt="custom-frame"
                                    className="feature-image"
                                />
                            </div>
                        </div>
                    </Grid>
                    {/* Personalized Support */}
                    <Grid
                        item
                        xs={12}
                        md={6}
                        ref={feature3n4}
                        className={
                            isMdOrLarger
                                ? inView3
                                    ? "show-feature"
                                    : "slide-right"
                                : "show-feature"
                        }
                        style={{ padding: "3rem" }}
                    >
                        <div className="feature-info-column-right">
                            <div className="text-heading">
                                Personalized Support
                            </div>
                            <p className="feature-info-paragraph text-description">
                                Connect with our dedicated team for tailored
                                solutions and exceptional assistance.
                            </p>
                            <div className="feature-image-normal">
                                <img
                                    src="Feature-4.webp"
                                    alt="custom-frame"
                                    className="feature-image"
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default FeatureView;
