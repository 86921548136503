// src/FAQItem.js
import React, { useState } from "react";

import "./FAQ.css";

const FAQItem = ({ question, answer, image, list }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    function convertEmailsToLinks(text) {
        const emailPattern =
            /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
        return text.replace(emailPattern, '<a href="mailto:$1">$1</a>');
    }
    const processedAnswer = convertEmailsToLinks(answer);

    return (
        <div className="faq-item">
            <div
                onClick={toggleVisibility}
                className="faq-question text-heading-small"
            >
                {question}
            </div>
            <div className={`faq-answer ${isVisible ? "show" : ""}`}>
                <div
                    className="faq-answer-text text-description"
                    dangerouslySetInnerHTML={{ __html: processedAnswer }}
                />
                {list && (
                    <ul>
                        {list.map((listItem, index) => (
                            <li
                                key={index}
                                className="text-description"
                                dangerouslySetInnerHTML={{ __html: listItem }}
                            ></li>
                        ))}
                    </ul>
                )}
                {image && (
                    <img src={image} alt="FAQ visual" className="faq-image" />
                )}
            </div>
        </div>
    );
};

export default FAQItem;
