import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import "./HeroView.css";

import ReactGA from "react-ga4";

function HeroView() {
    const handleDemoButtonClick = () => {
        ReactGA.event({
            category: "Buttons",
            action: "Button Click",
            label: "Try the Demo",
        });

        // Navigate to the desired URL in a new tab
        window.open(
            "https://blankwalldemo.myshopify.com/products/old-building/?arlaunchpad=1",
            "_blank",
        );
    };

    const handleSetupButtonClick = () => {
        window.location.href = "/setup";
    };

    return (
        <div className="background-primary">
            <Container maxWidth="xl">
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        className="hero-video-container"
                        align="center"
                    >
                        <div
                            style={{
                                borderRadius: "2rem",
                                overflow: "hidden",
                            }}
                        >
                            <video
                                src="./C0559_5.mp4"
                                autoPlay
                                loop
                                muted
                                playsInline
                                style={{
                                    width: "100%",
                                    display: "block",
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        style={{ textAlign: "left", display: "flex" }}
                    >
                        <Box alignContent={"center"}>
                            <Stack spacing={3} className="hero-content-stack">
                                <div className="hero-title">
                                    Make Every Room Your&nbsp;
                                    <span className="hero-gradient-text">
                                        Showroom
                                    </span>
                                </div>
                                <p className="hero-subtitle">
                                    Display your art anywhere with augmented
                                    reality. Boost customer confidence and
                                    increase sales.
                                </p>
                                <span className="hero-button-container">
                                    <button
                                        className="button"
                                        onClick={handleSetupButtonClick}
                                    >
                                        Setup for Free
                                    </button>
                                    <button
                                        className="button  button-secondary"
                                        onClick={handleDemoButtonClick}
                                    >
                                        Try the Demo
                                    </button>
                                </span>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default HeroView;
