import React from "react";
import Container from "@mui/material/Container";

const StyleGuideView = () => {
    const items = [
        { className: "text-eyebrow", text: "Eyebrow" },
        { className: "text-title", text: "Title - Responsive" },
        { className: "text-heading", text: "Heading - Responsive" },
        { className: "text-heading-small", text: "Heading Small - Fixed" },
        {
            className: "text-description",
            text: "Description - The quick brown fox jumps over the lazy dog.",
        },
    ];

    return (
        <div className="background-primary" style={{ paddingTop: "2rem" }}>
            <Container maxWidth="lg">
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={item.className}
                        style={{ padding: "1rem" }}
                    >
                        {item.text}
                    </div>
                ))}
            </Container>
        </div>
    );
};

export default StyleGuideView;
