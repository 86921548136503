import Container from "@mui/material/Container";
import { useInView } from "react-intersection-observer";
import "./IntegrationView.css";
import "../App.css";
import ProductListing from "../SetupView/ProductListing";

import useViewport from "../useViewport";

const IntegrationView = () => {
    const isMdOrLarger = useViewport();
    const [titleRef, inView1] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });
    const handleSetupButtonClick = () => {
        window.location.href = "/setup";
    };

    return (
        <>
            <div className="background-primary">
                <Container maxWidth="xl">
                    <div className="section-title">
                        <div className="text-eyebrow foreground-highlight">
                            How it Works
                        </div>
                        <div className="text-title">
                            Photo-realistic Previews
                            <br />
                            Built Right Into Your Storefront
                        </div>
                    </div>
                    <div style={{ paddingBottom: "4rem" }}>
                        <ProductListing />
                    </div>
                </Container>
            </div>

            <div className="integration-container">
                <Container maxWidth="xl">
                    <div
                        className={`section-title ${inView1 && isMdOrLarger ? "fade-in" : ""}`}
                        ref={titleRef}
                    >
                        <div className="text-eyebrow foreground-highlight">
                            Get Started
                        </div>
                        <div className="text-title">Dead-Simple Setup</div>
                        <p className="text-description">
                            Add the AR button to your product page and your
                            entire catalog is instantly supported.
                            <br /> No lengthy setup, no asset management.
                        </p>
                        <button
                            className="button"
                            onClick={handleSetupButtonClick}
                            style={{ padding: "0rem 2rem", maxWidth: "300px" }}
                        >
                            Setup for Free
                        </button>
                        <div>
                            <img
                                className="setup-img"
                                style={{ width: "auto", maxWidth: "100%" }}
                                src="automagically-nobtn.webp"
                                alt="automagically"
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default IntegrationView;
