import React from "react";
import Container from "@mui/material/Container";

import "./WidgetView.css";
import CodeSnippet from "./CodeSnippet";
import WidgetConfigurator from "./WidgetConfigurator";

function WidgetView() {
    const scriptImportCode = `
        <script src="https://dfv2bs0cn166o.cloudfront.net/blankwall.umd.cjs"></script>
    `;

    const newDivCode = `
        <div id='blankwall-container'></div>
    `;
    /*
    const newDivJsCode = `
        <script>
            var targetElement = document.getElementsByClassName('target-element-class')[0];
            var containerElement = document.createElement('div');
            containerElement.id = 'blankwall-container';
            targetElement.parentNode.insertBefore(containerElement, targetElement.nextSibling);
        </script>
    `;
 */
    const initCode = `
        <script>
            Blankwall.init(
                {
                    ar_container_selector: "#blankwall-container",
                    ar_image_selector: ".product-image",
                    ar_dimensions_selector: ".product-description",
                },
            );
        </script>
    `;

    const styleCode = `
        <script>
            Blankwall.init(
                {
                    ar_container_selector: "#blankwall-container",
                    ...
                },
                {
                  buttonText: "View in Your Space",
                  backgroundColor: "DarkSlateGrey",
                  color: "HoneyDew",
                  borderColor: "white",
                  borderRadius: "6px",
                  padding: "10px 20px",
                  ...
                },
            );
        </script>
    `;
    return (
        <div className="background-primary">
            <Container maxWidth="lg" style={{ paddingBottom: "2rem" }}>
                <div className="section-title">
                    <div className="text-eyebrow foreground-highlight">
                        Get Started
                    </div>
                    <div className="text-title">
                        Integrate AR previews into any site.
                    </div>
                    <p className="text-description">
                        Simply add the following HTML to your product page, and
                        your entire catalog is supported.
                        <br />
                        If you have any questions or need a helping hand, don't
                        hesitate to contact us at{" "}
                        <a href="mailto:help@dreamscapelabs.com">
                            help@dreamscapelabs.com
                        </a>
                    </p>
                </div>

                <div className="widget-steps-container">
                    <div className="text-heading widget-step-title">
                        1. Load the Blankwall script
                    </div>
                    <CodeSnippet code={scriptImportCode} />

                    <div className="widget-divider">
                        <div className="widget-divider-line"></div>
                    </div>
                </div>

                <div className="widget-steps-container">
                    <div className="text-heading widget-step-title">
                        2. Create a container to hold the AR button
                    </div>
                    {/*
                    <h3 className="widget-step-subtitle">
                        Option 1: Using HTML
                    </h3> */}
                    <CodeSnippet code={newDivCode} />
                    <p className="widget-step-content">
                        Add the container <b>&lt;div&gt;</b> where you want the{" "}
                        <b>AR Button</b> to be placed.
                    </p>
                    {/*
                    <h3 className="widget-step-subtitle">
                        Option 2: Using Javascript
                    </h3>
                    <p className="widget-step-content">
                        Create the <b>container &lt;div&gt;</b> programatically,
                        positioned <b>relative</b> to an existing target
                        element.
                    </p>
                    <CodeSnippet code={newDivJsCode} />
*/}
                    <div className="widget-divider">
                        <div className="widget-divider-line"></div>
                    </div>
                </div>

                <div className="widget-steps-container">
                    <div className="text-heading widget-step-title">
                        3. Initialize Blankwall
                    </div>
                    <CodeSnippet code={initCode} />
                    <p className="widget-step-content">
                        Blankwall requires an initialization parameter with 3
                        fields:
                    </p>
                    <ol className="widget-step-list-container">
                        <li className="widget-step-list-content">
                            <code>
                                <b>ar_container_selector</b>
                            </code>{" "}
                            : The selector of the container element created in{" "}
                            <b>step 02</b>. The AR button will be placed inside
                            this element.
                        </li>
                        <li className="widget-step-list-content">
                            <code>
                                <b>ar_image_selector</b>
                            </code>{" "}
                            : The selector of the element containing the product
                            image you want to use for AR. This must be an{" "}
                            <code>
                                <b>&lt;img&gt;</b>
                            </code>{" "}
                            element where the{" "}
                            <code>
                                <b>src</b>
                            </code>{" "}
                            attribute points to the URL of the image.
                        </li>
                        <li className="widget-step-list-content">
                            <code>
                                <b>ar_dimensions_selector</b>
                            </code>{" "}
                            : The selector of the element containing the
                            dimensions of the asset in plain text. For example,
                            a product description block. Blankwall will
                            automatically parse out the dimensions from a larger
                            body of text if required.
                        </li>
                    </ol>

                    <div className="widget-divider">
                        <div className="widget-divider-line"></div>
                    </div>
                    <div className="text-heading widget-step-title">
                        Customizations
                    </div>
                    <p className="widget-step-content">
                        Blankwall supports standard CSS inline-styles so you can
                        easily customize the AR button to fit your store's
                        unique look.
                    </p>
                    <CodeSnippet code={styleCode} />
                    <p className="widget-step-content">
                        The Blankwall <code>init()</code> function supports an
                        optional second parameter for styling the AR button. In
                        this parameter, you can specify standard inline CSS in
                        camelCase format.
                    </p>
                    <p className="widget-step-content">
                        In addition, you can customize the primary text on the
                        button using the custom <code>buttonText</code> field.
                    </p>
                    <div className="widget-divider">
                        <div className="widget-divider-line"></div>
                    </div>

                    <WidgetConfigurator />
                </div>
            </Container>
        </div>
    );
}

export default WidgetView;
