import { useState } from "react";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import InstagramIcon from "@mui/icons-material/Instagram";

import { AppBarViewButton, AppBarViewContactButton } from "../ButtonTheme";

import "./AppBarView.css";

// Track AppBar links
import ReactGA from "react-ga4";

const mainColor = "#fafafc";

function AppBarView() {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    // Send data through ReactGA.event
    const handleAppBarButtonClick = (buttonName) => {
        ReactGA.event({
            category: "Buttons",
            action: "Button Click",
            label: buttonName,
        });
        // Will show up as Button Click, each label is nested underneath
    };

    return (
        <AppBar
            color="transparent"
            sx={{ boxShadow: 0, background: mainColor, py: "0.5rem" }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {isMatch ? (
                        <>
                            <Link
                                to="/"
                                style={{ textDecoration: "none", zIndex: "99" }}
                            >
                                <img
                                    src="logo192.webp"
                                    className="appbar-logo"
                                    alt="logo"
                                />
                            </Link>
                            <Link
                                to="/"
                                style={{ textDecoration: "none", zIndex: "99" }}
                            >
                                <h1 className="Dreamscape-Labs">
                                    Dreamscape Labs
                                </h1>
                            </Link>

                            <button
                                className={`hamburger ${isOpen ? "is-active" : ""}`}
                                onClick={toggleNav}
                            >
                                <div className="bar"></div>
                            </button>
                            {isOpen && (
                                <nav className="mobile-nav background-primary">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://apps.shopify.com/blankwall"
                                    >
                                        Blankwall App
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.instagram.com/blankwall.app/"
                                    >
                                        Instagram
                                    </a>
                                    <a href="/setup">Setup</a>
                                    <a href="/contact">Contact</a>
                                </nav>
                            )}
                        </>
                    ) : (
                        <>
                            <Link to="/" style={{ textDecoration: "none" }}>
                                <img
                                    src="logo192.webp"
                                    className="appbar-logo"
                                    alt="logo"
                                />
                            </Link>
                            <Link to="/" style={{ textDecoration: "none" }}>
                                <b className="Dreamscape-Labs">
                                    Dreamscape Labs
                                </b>
                            </Link>
                            <span style={{ right: 0, position: "absolute" }}>
                                <AppBarViewButton
                                    onClick={() =>
                                        handleAppBarButtonClick("appbar-setup")
                                    }
                                    color="primary"
                                    rel="noopener noreferrer"
                                    href="/setup"
                                >
                                    Setup
                                </AppBarViewButton>
                                <AppBarViewButton
                                    onClick={() =>
                                        handleAppBarButtonClick("appbar-sas")
                                    }
                                    sx={{ ml: "6px" }}
                                    color="primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://apps.shopify.com/blankwall"
                                >
                                    <img
                                        className="appbar-shopify"
                                        src="./shopify_glyph_black.svg"
                                        alt="shopify-logo"
                                    />
                                    Blankwall&nbsp;App
                                </AppBarViewButton>
                                <AppBarViewButton
                                    onClick={() =>
                                        handleAppBarButtonClick("appbar-ins")
                                    }
                                    sx={{ ml: "6px" }}
                                    color="primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/blankwall.app/"
                                >
                                    <InstagramIcon sx={{ mr: "6px" }} />
                                    Instagram
                                </AppBarViewButton>
                                <AppBarViewContactButton
                                    onClick={() =>
                                        handleAppBarButtonClick(
                                            "appbar-contact",
                                        )
                                    }
                                    color="primary"
                                    sx={{ ml: "1em" }}
                                    href="/contact"
                                >
                                    Contact
                                </AppBarViewContactButton>
                            </span>
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default AppBarView;
