import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import "./EmailUsView.css";

// Send pageview for /contact
import ReactGA from "react-ga4";
ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact Us" });

export default function EmailUsView() {
    return (
        <div className="background-secondary">
            <Container maxWidth="xl">
                <Grid container className="emailus-container">
                    <Grid className="emailus-textblock" item xs={12} md={6}>
                        <h1 className="emailus-description text-heading">
                            Captivate customers with interactive and immersive
                            shopping experiences.
                        </h1>
                        <h1 className="emailus-title-blankwall">Blankwall</h1>
                        <p className="emailus-title text-description">
                            Get in touch for integration details.
                        </p>
                    </Grid>
                    <Grid className="emailus-imageblock" item xs={12} md={6}>
                        <a
                            className="emailus-email-link"
                            align="center"
                            href="mailto:hello@dreamscapelabs.com"
                        >
                            hello@dreamscapelabs.com
                        </a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
